export const ua = {
  title: 'Термінал',
  account: 'Рахунок',
  uah: 'грн',
  helpline: 'Ми завжди поруч',
  helpline_2: ', телефонуйте',
  search: 'Пошук',
  loading: 'Завантаження даних',
  timer_question: 'Ви ще тут?',
  duration: 'Тривалість',
  welcome_title: 'Вітаємо в терміналі самообслуговування!',
  welcome_subtitle: 'Будь ласка, оберіть послугу',
  choose_service: 'Оберіть послугу',
  error: 'Помилка',
  result_ticket_num: 'Номер Вашого талону: {num}',
  ticket_num: 'Номер талону',
  terminal: 'Термінал',
  address: 'Адреса',
  help_line_phone: 'Телефон гарячої лінії',
  approximate_at: 'приблизно о',
  confirmation: 'Підтвердження',
  attention: 'Увага!',
  choose_time: 'Вибір часу',
  free_time: 'Вільний час',
  days: ['Пн', 'Вт',' Ср', 'Чт', 'Пт',' Сб', 'Нд'],
  header: {
    info_button: 'Згода на обробку персональних даних',
    rates_button: 'Інформація та тарифи',
    qr_text_1: 'Стабільність, ефективність та прозорість у кожному потоці!',
    qr_text_2: 'Дізнайтеся більше про наш сервіс електронної черги',
  },
  footer: {
    button_back: 'Назад',
    button_main: 'Головна',
    button_next: 'Далі',
    button_continue: 'Продовжити',
  },
  permission: {
    part_1: 'Надаю згоду на збір, обробку та використання наданих мною персональних даних.',
    part_2: 'З правами суб\'єкта персональних даних ознайомлений(на).',
  },
  button: {
    ok: 'Ок',
    nearest: 'Найближчий час',
    edit: 'Редагувати',
    add_queue: 'Записатись',
    yes: 'Так',
    reload: 'Перезавантажити',
    to_main: 'На головну',
    permission: 'Згода на обробку персональних даних',
    submit_queue_live: 'Підтвердити найближчий час',
    cancel: 'Відміна',
    submit_time: 'Підтвердити час',
    other_time: 'Обрати інший час',
    close: 'Закрити',
    print: 'Роздрукувати талон',
  },
  queue: {
    available_time: 'Доступний час запису на',
    no_available_time: 'Немає доступного часу для запису на',
    fixed: 'На іншу дату та час',
    live_1: 'Поточна черга',
    live_2: '(на сьогодні)',
    no_near_1: 'На даний момент, талони до поточної черги, відсутні',
    no_near_2: 'Будь ласка, оберіть зручний для вас час на іншу дату',
    choose_period: 'Будь ласка, оберіть варіант реєстрації в чергу',
    no_dates: 'Немає доступних дат',
    approximate_time: 'Приблизний час очікування',
    appearance_time: 'Очікується поява талонів через',
    in_queue: 'Осіб в черзі на вибрану послугу',
    current: 'Поточна',
    more_than: 'Більше, ніж',
    live_gotovo_1: 'Час вказано орієнтовно!',
    live_gotovo_2: 'Не залишайте підрозділ, Вас можуть викликати раніше.',
    live_gotovo_3: 'Слідкуйте за чергою на табло.',
    live_gotovo_4: 'Якщо Вас не влаштовує запропонований час, Ви можете обрати інші час і дату',
    ticket_amount: 'Талонів на сьогодні',
    free: 'Вільних',
    confirm_title: 'Для завершення реєстрації в черзі зателефонуйте, будь ласка, з номера',
    confirm_phones: 'на один з номерів зі списку:',
    confirm_time: 'Час для підтвердження',
  },
  message: {
    success: 'Успіх',
    fail: 'Невдача',
    queue_service_error: 'На даний час неможливо записатись в чергу на послугу \'{name}\'',
    queue_time_error: 'Не вдалось записатись на час {time}',
    load_error: 'Не вдалось завантажити дані',
    server_error: 'Втрачено зв\'язок з сервером',
    timeout_error: 'Вийшов час очікування відповіді від сервера',
    info_error: 'Щось пішло не так... Спробуйте звернутись пізніше'
  },
  breadcrumb: {
    'queue-period': 'Дата та час',
    'queue-form': 'Введення даних',
    'queue-results': 'Результати',
    'queue-tree': 'Вибір послуги',
  },
  result: {
    datePeriod: 'Дата',
    organizationId: 'Організація',
    regionId: 'Регіон',
    region: 'Регіон',
    serviceId: 'Послуга',
    servicePackageId: 'Пакет послуг',
    timePeriod: 'Час',
    current: 'Тип черги'
  },
  errors: {
    ERROR_LOGIN: 'Помилка при авторизації',
    ERROR_NO_SSE: 'Немає sse',
    ERROR_REGION_SELECT: 'Не обраний регіон',
    ERROR_NO_REGION: 'Немає регіонів',
    ERROR_ORGANIZATION_SELECT: 'Не обрана організація',
    ERROR_NO_ORGANIZATION: 'Немає організацій',
    ERROR_NO_SERVICE_PACKAGE: 'Немає пакетів послуг',
    ERROR_NO_SERVICE_PACKAGE_ITEM: 'Немає компонентів пакетів послуг',
    ERROR_SERVICE_PACKAGE_NOT_AVAILABLE: 'Пакет послуг недоступний',
    ERROR_SERVICE_SELECT: 'Не обрана послуга',
    ERROR_NO_SERVICE: 'Немає послуг',
    ERROR_DATE_SELECT: 'Не обрана дата',
    ERROR_TIME_RESERVED: 'Час зарезервовано',
    ERROR_TIME_SELECT: 'Не обрано час',
    ERROR_NO_CLIENT: 'Немає клієнтів',
    ERROR_CLIENT_SELECT: 'Не обраний клієнт',
    ERROR_NO_OPERATOR: 'Немає операторів',
    ERROR_OPERATOR_SELECT: 'Не обраний оператор',
    ERROR_NO_SCHEDULE: 'Немає розкладів',
    ERROR_SCHEDULE_SELECT: 'Не обрано розклад',
    ERROR_NO_SIGNATURE: 'Немає сигнатури',
    ERROR_SIGNATURE_SELECT: 'Не обрана сигнатура',
    ERROR_REQUIRED: 'Введені не всі необхідні дані',
    ERROR_SHORT_TEXT: 'Помилка: SHORT_TEXT',
    ERROR_PHONE: 'Невірний номер телефону',
    ERROR_EMAIL: 'Невірна адреса електронної пошти',
    ERROR_CHECK_LIMIT: 'Перевищений ліміт. Кількість спроб вичерпано',
    ERROR_CHECK_LIMIT_FIO:
      'Дані, які Ви ввели для отримання талона в електронну чергу на цю дату вже використовуються для іншого запису. Рекомендуємо здійснити спробу запису в електронну чергу на іншу дату.',
    ERROR_CHECK_LIMIT_FIO_ONLY: 'На жаль, система не може здійснити запис з такими даними в електронну чергу.' +
      '<br/>' +
      '<span class="smaller">Рекомендуємо перевірити прізвище, вказане при реєстрації, адже воно має бути введене українською мовою з великої літери без жодних додаткових знаків.</span>' +
      '<br/>' +
      '<span class="smaller">Зверніть увагу, що дане прізвище, також, не може бути використане для запису в електронну чергу більше трьох разів на тиждень.</span>',
    //ERROR_CHECK_LIMIT_FIO_ONLY: 'Будь ласка, введіть винятково прізвище.',
    ERROR_ADD_RESERVATION: 'Час зарезервовано',
    disagree: 'Ви не підтвердили згоду на збір, обробку та використання персональних даних',
    datetime_choose: 'Ви не обрали час чи дату',
    time_exceeded: 'Час очікування вичерпаний',
  },

  units: {
    hours: 'год',
    minutes: 'хв',
    seconds: 'сек',
  },

  equick: {
    'queue-tree': 'Вибір послуги',
    'queue-period': 'Дата та час',
    'queue-form': 'Дані',
    'queue-results': 'Підтвердження',

    main_title: 'Оберіть послугу або скористайтеся пошуком.',
    form_title: 'Заповніть дані:',
    results_title: 'Підтвердження даних',
    input_placeholder: 'Введіть дані',
    search_placeholder: 'Введіть назву послуги',
    submit_button: 'Погоджуюсь',
    not_found: 'Нічого не знайдено',
    queue_current_title: 'Ви можете записатися у поточну чергу,<br />або обрати зручну для вас дату та час',
    queue_current_people: 'Наразі у черзі:',
    queue_current_time: 'Приблизний час очікування:',
    queue_current_wait: 'Очікується поява талонів через:',
    queue_current_register: 'Записатися у поточну чергу',
    queue_current_choose_date: 'Перейти до вибору дати та часу',
    queue_fixed_choose_date: 'Оберіть дату:',
    queue_fixed_choose_time: 'Оберіть час:',
    queue_fixed_date_error: 'Не вдалося завантажити доступний час для обраної дати',
  }
};
